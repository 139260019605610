/* eslint-disable max-lines */
import
{
  APIService, unwrapResponse
} from "./api.service";
import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";
import
{
  Intervention, InterventionInsert, InterventionUpdate
} from "../models";
import
{
  Observable, catchError, of
} from "rxjs";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InterventionSearch } from "../models/intervention-search";
import { PartialData } from "../models";
import { ActivatedRoute } from "@angular/router";
import { InterventionActionType } from "../../intervention/intervention-constants";


@Injectable({
  providedIn: "root"
})
export class InterventionService
{
  static readonly url = "Intervention";

  private handleError: ErrorHandlerCreator;

  constructor(protected readonly api: APIService,
    readonly responseErrorHandler: ResponseErrorHandler,
    private route: ActivatedRoute
  )
  {
    this.handleError = responseErrorHandler.createErrorHandler("InterventionService");
  }

  getInterventions(skip: number, take: number, eventType?: string)
    : Observable<PartialData<Intervention> | null>
  {
    eventType = this.route.snapshot.queryParamMap.get("interventionViewType") as string;

    return this.api.get<PartialData<Intervention>>(
      `${InterventionService.url}?skip=${skip}&take=${take}&eventType=${eventType}`
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getIntervention", null))
    );
  }

  getInterventionsCounters()
    : Observable<Record<string, number>>
  {
    return this.api.get<Record<string, number>>(
      `${InterventionService.url}/GetCounters`
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("GetCounters", {}))
    );
  }

  SearchInterventionsByPerson(studentId: string, skip: number, take: number)
    : Observable<PartialData<Intervention> | null>
  {
    let params = new HttpParams();

    params = params.set("studentId", studentId).set("skip", skip).set("take", take);
    const options = { params: params };

    return this.api.get<PartialData<Intervention>>(
      `${InterventionService.url}/searchByStudent`, options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("SearchInterventionsByStudent", null))
    );
  }

  SearchIntervention(interventionSearch: InterventionSearch,
    skip: number, take: number)
    : Observable<PartialData<Intervention> | null>
  {
    let params = new HttpParams();
    const eventType = this.route.snapshot.queryParamMap.get("interventionViewType") as string;

    params = params.set("skip", skip).set("take", take);
    params = params.set("eventType", eventType);

    if (interventionSearch)
    {
      Date.prototype.toJSON = function () {
        return this.toISOString();
      };
      params = params.set("jsonSearchParams", JSON.stringify(interventionSearch));
      }

    const options = { params: params };

    return this.api.get<PartialData<Intervention>>(
      `${InterventionService.url}/Search`, options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("SearchIntervention", null))
    );
  }
  approveInterventions(Id: string): Observable<boolean | null>
  {
    return this.api.post<boolean>(InterventionService.url, { Id: Id }).pipe(
      unwrapResponse<boolean>(),
      catchError(this.handleError("approveInterventions", false))
    );
  }

  getInterventionUpdate(Id: string): Observable<InterventionUpdate>
  {
    return this.api.get<InterventionUpdate>(
      InterventionService.url + "/GetInterventionForUpdate?id=" + Id
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getInterventionForUpdate"))
    );
  }



  deleteInterventions(Id: string): Observable<string>
  {
    // For debugging
    return of(Id);
  }

  addIntervention(interventionInsert: InterventionInsert): Observable<boolean | null>
  {
    return this.api.post<boolean>(InterventionService.url + "/insert", interventionInsert).pipe(
      unwrapResponse<boolean>(),
      catchError(this.handleError("addInterventionInterventions", false))
    );
  }

  updateIntervention(interventionUpdate: InterventionUpdate): Observable<boolean | null>
  {
    return this.api.post<boolean | null>(InterventionService.url + "/UpdateIntervention", interventionUpdate).pipe(
      unwrapResponse(),
      catchError(this.handleError("addInterventionInterventions", false))
    );
  }

  updateInterventionStatus(interventionId: string, actionType: InterventionActionType): Observable<boolean | null>
{
    const url = `${InterventionService.url}/updateStatus/${interventionId}/${actionType}`;
    return this.api.post<boolean | null>(url, {}).pipe(
      unwrapResponse(),
      catchError(this.handleError("updateInterventionStatus", false))
    );
  }
  
  checkIfInterventionApprovalExists(studentId:string): Observable<boolean>
  {
    return this.api.get<boolean>(`${InterventionService.url}/CheckValidInterventionApproval?studentId=${studentId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("CheckIfInterventionApprovalExists"))
    );
  }
}